export enum UserStatuses {
  NEW = 0,
  ONBOARDED = 1,
  ACTIVE = 2,
  BLOCKED = 255,

  START = 300,
  EXPIRED = 301,
}

export type CustomField = {
  name: string;
  type: string;
  label: string;
  description: string;
  value: string | null;
  required: boolean;
  placeholder: string;
};

export type NoteType = {
  createdAt: string;
  id: number;
  note: string;
  title: string;
  updatedAt: string;
  uuid: string;
};

export enum SubscriptionStatusEnum {
  INACTIVE,
  ACTIVE,
  TRIAL,
  CANCELLED,
  PAST_DUE,
}

export type Subscription = {
  expiredAt: string;
  plan: string;
  planId: number;
  status: SubscriptionStatusEnum;
};

export enum LimitType {
  SUBSCRIPTION = 0,
  PURCHASE = 1,
  REFERRAL = 2,
  MANUAL = 3,
}

export type User = {
  id: number;
  name: string;
  phone: string;
  useOverage: boolean;
  subscription: Subscription | null;
  limits: [
    {
      type: number;
      credits: number;
      used: number;
    }
  ];
  referralCode: string;
  referrersCount: number;
  customFields: Record<string, any>;
  queue?: number;
} | null;

export type Plan = {
  credits: number;
  description: string;
  id: number;
  isDefault: boolean;
  name: string;
  order: number;
  price: string;
  priceOverage: string;
  renewable: boolean;
  status: number;
  trialCredits: number;
  trialPeriodDays: number;
};
