import * as React from "react";
import { format, startOfYear, endOfYear, eachMonthOfInterval } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/app/helpers/utils";
import styles from "./styles.module.scss";
import { Label } from "../ui/label";

interface DatePickerProps {
  dateString: string;
  setFormValue: (date: string | undefined) => void;
  placeholder: string;
  label?: string;
  description?: string;
  error?: boolean;
  errorText?: string;
  id: string;
  disabled?: boolean;
}

export default function DatePicker({
  dateString,
  setFormValue,
  placeholder,
  label,
  description,
  error,
  errorText,
  id,
  disabled = false,
}: DatePickerProps) {
  const [date, setDate] = React.useState(
    !isNaN(Date.parse(dateString)) ? new Date(dateString) : undefined
  );
  const [month, setMonth] = React.useState<number>(
    date ? date.getMonth() : new Date().getMonth()
  );
  const [year, setYear] = React.useState<number>(
    date ? date.getFullYear() : new Date().getFullYear()
  );

  const years = React.useMemo(() => {
    const currentYear = new Date().getFullYear();
    return Array.from(
      { length: currentYear - 1900 + 1 },
      (_, i) => currentYear - i
    );
  }, []);

  const months = React.useMemo(() => {
    if (year) {
      return eachMonthOfInterval({
        start: startOfYear(new Date(year, 0, 1)),
        end: endOfYear(new Date(year, 0, 1)),
      });
    }
    return [];
  }, [year]);

  React.useEffect(() => {
    if (date) {
      setMonth(date.getMonth());
      setYear(date.getFullYear());
    }
  }, [date]);

  React.useEffect(() => {
    setFormValue(date ? format(date, "MM/dd/yyyy") : undefined);
  }, [date]);

  const handleYearChange = (selectedYear: string) => {
    const newYear = parseInt(selectedYear, 10);
    setYear(newYear);
    if (date) {
      const newDate = new Date(date);
      newDate.setFullYear(newYear);
      setDate(newDate);
    }
  };

  const handleMonthChange = (selectedMonth: string) => {
    const newMonth = parseInt(selectedMonth, 10);
    setMonth(newMonth);
    if (date) {
      const newDate = new Date(date);
      newDate.setMonth(newMonth);
      setDate(newDate);
    } else {
      setDate(new Date(year, newMonth, 1));
    }
  };

  return (
    <Popover>
      {label && (
        <Label htmlFor={id} className="field-label">
          {label}
        </Label>
      )}
      <PopoverTrigger asChild>
        <Button
          className={cn(
            "w-full ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 ring-black",
            styles.popoverOpenButton,
            error && styles.hasError,
            disabled && styles.disabled,
            date && styles.hasDate
          )}
          id={id}
          type="button"
          tabIndex={0}
        >
          <CalendarIcon className={styles.icon} />
          <p className={styles.text}>
            {date ? format(date, "MM/dd/yyyy") : placeholder}
          </p>
        </Button>
      </PopoverTrigger>
      {description && !error && (
        <p className="field-description">{description}</p>
      )}
      {error && errorText && <p className="field-error">{errorText}</p>}
      {!disabled && (
        <div className={styles.popoverContentContainer}>
          <PopoverContent
            className={cn("w-auto p-0", styles.content)}
            align="start"
          >
            <div className="flex justify-between p-2 space-x-1">
              <Select onValueChange={handleYearChange} value={year.toString()}>
                <SelectTrigger className="w-[120px]">
                  <SelectValue placeholder="Year" />
                </SelectTrigger>
                <SelectContent>
                  {years.map((y) => (
                    <SelectItem key={y} value={y.toString()}>
                      {y}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Select
                onValueChange={handleMonthChange}
                value={month.toString()}
              >
                <SelectTrigger className="w-[120px]">
                  <SelectValue placeholder="Month" />
                </SelectTrigger>
                <SelectContent>
                  {months.map((m, index) => (
                    <SelectItem key={index} value={index.toString()}>
                      {format(m, "MMMM")}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <Calendar
              mode="single"
              selected={date}
              onSelect={setDate}
              month={new Date(year, month)}
              onMonthChange={(newMonth) => {
                setMonth(newMonth.getMonth());
                setYear(newMonth.getFullYear());
              }}
              initialFocus
            />
          </PopoverContent>
        </div>
      )}
    </Popover>
  );
}
