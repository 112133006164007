import React from "react";
import { cn } from "@/app/helpers/utils";
import { Label } from "../ui/label";
import styles from "./styles.module.scss";

type Props = {
  disabled?: boolean;
  label?: string;
  value?: string;
  setValue?: (value: string) => void;
  description?: string;
  className?: string;
  error?: boolean;
  errorText?: string;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      label,
      description,
      className,
      value,
      setValue,
      error,
      errorText,
      ...props
    },
    ref
  ) => {
    return (
      <>
        {label && (
          <Label htmlFor={props?.id} className="field-label">
            {label}
          </Label>
        )}
        <textarea
          ref={ref}
          value={value}
          onChange={(e) => setValue && setValue(e.target.value)}
          className={cn(
            "flex min-h-[80px] w-full ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 resize-none",
            styles.textarea,
            error && styles.hasError,
            className
          )}
          {...props}
          autoComplete="off"
        />
        {description && !error && (
          <p className="field-description">{description}</p>
        )}
        {error && errorText && <p className="field-error">{errorText}</p>}
      </>
    );
  }
);

Textarea.displayName = "Textarea";

export default Textarea;
