import { create } from "zustand";
import { CustomField, Plan, User, UserStatuses } from "./lib/types";

type State = {
  user: User;
  userStatus: UserStatuses;
  isFromMagicLink: boolean;
  customFields: CustomField[];
  shouldRefetchNotes: boolean;
  plans: Plan[];
  noNotesData: boolean | null;
};

type Action = {
  setUserStatus: (status: State["userStatus"]) => void;
  setUser: (user: State["user"]) => void;
  setIsFromMagicLink: (state: State["isFromMagicLink"]) => void;
  setCustomFields: (fields: State["customFields"]) => void;
  setShouldRefetchNotes: (state: State["shouldRefetchNotes"]) => void;
  setPlans: (plans: State["plans"]) => void;
  setNoNotesData: (state: State["noNotesData"]) => void;
};

const userStore = create<State & Action>((set) => ({
  userStatus: UserStatuses.START,
  setUserStatus: (status) => set(() => ({ userStatus: status })),
  isFromMagicLink: false,
  setIsFromMagicLink: (state) => set(() => ({ isFromMagicLink: state })),
  user: null,
  setUser: (user) => set(() => ({ user })),
  customFields: [],
  setCustomFields: (customFields) => set(() => ({ customFields })),
  shouldRefetchNotes: false,
  setShouldRefetchNotes: (state) => set(() => ({ shouldRefetchNotes: state })),
  plans: [],
  setPlans: (plans) => set(() => ({ plans })),
  noNotesData: null,
  setNoNotesData: (state) => set(() => ({ noNotesData: state }))
}));

export default userStore;
