const LoaderIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_210_5127)">
      <path
        d="M7.99992 1.33325V3.99992M10.7999 5.19985L12.7332 3.26652M11.9999 7.99992H14.6666M10.7999 10.7999L12.7332 12.7332M7.99992 11.9999V14.6666M3.26652 12.7332L5.19985 10.7999M1.33325 7.99992H3.99992M3.26652 3.26652L5.19985 5.19985"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_210_5127">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LoaderIcon;
