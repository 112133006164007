import React from "react";
import { cn } from "@/app/helpers/utils";
import { Label } from "../ui/label";
import styles from "./styles.module.scss";

type Props = {
  disabled?: boolean;
  value?: string;
  setValue?: (value: string) => void;
  label?: string;
  description?: string | React.ReactNode;
  className?: string;
  type?: string;
  error?: boolean;
  errorText?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      description,
      className,
      value,
      setValue,
      type = "text",
      error,
      errorText,
      ...props
    },
    ref
  ) => {
    return (
      <>
        {label && (
          <Label htmlFor={props?.id} className="field-label">
            {label}
          </Label>
        )}
        <input
          ref={ref}
          tabIndex={0}
          type={type}
          value={value}
          onChange={(e) => setValue && setValue(e.target.value)}
          className={cn(
            "w-full ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
            styles.input,
            error && styles.hasError,
            className
          )}
          {...props}
          autoComplete="off"
        />
        {description && !error && (
          <p className="field-description">{description}</p>
        )}
        {error && errorText && <p className="field-error">{errorText}</p>}
      </>
    );
  }
);

Input.displayName = "Input";

export default Input;
