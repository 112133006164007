import React from "react";
import { cn } from "@/app/helpers/utils";
import styles from "./styles.module.scss";
import LoaderIcon from "../icons/LoaderIcon";

type ButtonVariant = "primary" | "secondary" | "outline" | "ghost" | "link";

type Props = {
  leftIcon?: React.ReactElement | null;
  rightIcon?: React.ReactElement | null;
  className?: string;
  label?: string;
  loading?: boolean;
  typeVariant?: ButtonVariant;
  isActive?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<Props> = ({
  className,
  label,
  leftIcon,
  rightIcon,
  loading,
  typeVariant = "primary",
  isActive,
  ...props
}) => {
  return (
    <button
      className={cn(
        "w-full",
        loading && styles.loading,
        styles.button,
        !label && styles.onlyIcon,
        !label && loading && styles.hide,
        isActive && styles.active,
        styles[typeVariant],
        className
      )}
      disabled={loading || props.disabled}
      {...props}
      tabIndex={0}
    >
      {leftIcon && (
        <div className='icon'>
           {leftIcon}
        </div>
      )}
      {label}
      {rightIcon && !loading && (
        <div className='icon'>
          {rightIcon}
        </div>
      )}
      {loading && (
        <div className={cn('icon', styles.spinner)}>
          <LoaderIcon />
        </div>
      )}
    </button>
  );
};

export default Button;
