import React, { useState, useEffect } from "react";
import { cn } from "@/app/helpers/utils";
import { Label } from "../ui/label";
import styles from "./styles.module.scss";
import CheckIcon from "../icons/CheckIcon";

type Props = {
  disabled?: boolean;
  checked?: boolean;
  setChecked?: (checked: boolean) => void;
  id: string;
  label: string | React.ReactNode;
  description?: string;
  className?: string;
  error?: boolean;
  errorText?: string;
};

const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      disabled = false,
      checked,
      setChecked,
      id,
      label,
      description,
      error,
      errorText,
      className,
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [focusFromKeyboard, setFocusFromKeyboard] = useState(false);

    useEffect(() => {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Tab") {
          setFocusFromKeyboard(true);
        }
      };

      const handleMouseDown = () => {
        setFocusFromKeyboard(false);
      };

      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("mousedown", handleMouseDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
        document.removeEventListener("mousedown", handleMouseDown);
      };
    }, []);

    return (
      <div
        className={cn(
          styles.Checkbox,
          className,
          disabled && styles.disabled,
          error && styles.hasError
        )}
      >
        <div
          className={styles.checkboxContainer}
          onClick={() => setChecked && setChecked(!checked)}
        >
          <input
            tabIndex={0}
            type="checkbox"
            autoComplete="off"
            id={id}
            ref={ref}
            checked={checked}
            disabled={disabled}
            className={styles.checkboxInput}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          <label
            htmlFor={id}
            className={cn(
              styles.checkboxLabel,
              "icon",
              isFocused &&
                focusFromKeyboard &&
                "ring-offset-background ring-2 ring-ring ring-offset-2 ring-black"
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <CheckIcon />
          </label>
        </div>

        <div className={styles.labelContainer}>
          {label && (
            <Label htmlFor={id} className={cn(styles.title)}>
              {label}
            </Label>
          )}
          {description && !error && (
            <p className="field-description">{description}</p>
          )}
          {error && errorText && <p className="field-error">{errorText}</p>}
        </div>
      </div>
    );
  }
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
